.primary {
  @apply bg-gray-700 text-white-900 hover:bg-orange-500;
}

.secondary {
  @apply bg-orange-500 text-white-900 hover:bg-gray-700;
}

.lens {
  @apply bg-lens-primary text-white-900 hover:bg-opacity-90;
}

.success {
  @apply bg-t2-success-darkGreen text-white-900 hover:backdrop-brightness-75;
}

.ghost {
  @apply shadow-none text-t2-neutral-grey08;
}

.primary-outlined {
  @apply border border-black-700 text-gray-700 hover:bg-gray-700 hover:text-white-900;
}

.secondary-outlined {
  @apply border border-orange-500 text-orange-500 hover:bg-orange-500 hover:text-white-900;
}

.lens-outlined {
  @apply border border-lens-primary text-lens-primary hover:bg-lens-primary hover:text-white-900;
}

.success-outlined {
  @apply border border-t2-success-darkGreen text-t2-success-darkGreen hover:bg-t2-success-darkGreen hover:text-white-900;
}

.ghost-outlined {
  @apply border text-t2-neutral-grey08 border-t2-neutral-grey03 hover:bg-t2-neutral-grey03;
}

.ghost-outlined {
  @apply border text-t2-neutral-grey08 border-t2-neutral-grey03 hover:bg-t2-neutral-grey03;
}

.primary-outlined:disabled,
.secondary-outlined:disabled,
.lens-outlined:disabled,
.success-outlined:disabled,
.ghozt-outlined:disabled {
  @apply border border-gray-400 text-gray-400 pointer-events-none shadow-none;
}

.primary:disabled,
.secondary:disabled,
.ghost:disabled,
.success:disabled {
  @apply bg-gray-200 text-gray-400 pointer-events-none shadow-none;
}
