.primary {
  @apply bg-t2-neutral-grey09 hover:bg-t2-primary-brandColor text-t2-neutral-grey01;
}

.secondary {
  @apply bg-t2-primary-brandColor hover:bg-t2-neutral-grey09 text-t2-neutral-grey01;
}

.ghost {
  @apply bg-transparent border border-t2-neutral-grey09 hover:bg-t2-neutral-grey02 text-t2-neutral-grey09;
}

.disabled {
  @apply bg-t2-neutral-grey03 !text-t2-neutral-grey05 pointer-events-none !shadow-none;
}
